.dbg {
    text-align: center;
    width: 100%;
    height: 32rem;
    background-image: url("../../public/images/dbg/background.png");
    background-size: cover;
    color: white;

}
.background-row {
    background-color: #f2f2f2; /* 灰色背景色 */
}

html,body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    min-width: 1024px;
    min-height: 600px;
    user-select: none; /* 禁止鼠标拖动页面时选中页面文字 */
}

.main {
    width: 100%;
    height: 100%;
}
.image_fixed {
    width: 700px;
    height: 300px
}