.perf {
    width: 100%;
    height: 34rem;
    background-image: url("../../public/images/perf/background.png");
    background-position:center;
    background-position-y: bottom;
    background-size: cover;

}

.line::after {
     content: "";
     display: block;
     width: 5rem;
     height: 2px;
     background-color: blue;
 }
/*.relative-l{*/
/*    position: relative;*/
/*    light: 40%;*/
/*}*/
.background-row {
    background-color: #f2f2f2; /* 灰色背景色 */
}
.content-container {
    box-sizing: border-box;

    @media (max-width: 1100px) {
        width: 100%;
        padding: 10px;
        /*background-color: black;*/
    }

}