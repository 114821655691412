.home {
    text-align: center;
    width: 100%;
    background-image: url("../../public/images/home/background1.png");
    background-size: cover;
    background-position:center;
    color: white;
}
.shadow{
    width: 100%;
    height: 10em;
    box-shadow: inset 0px 1px 2px 0px rgba(255, 255, 255, 0.61);
    background-color: rgba(2, 22, 73, 0.7);
}

.icon{
    padding:1.5rem;
    background-image:url("../../public/images/home/icon-background.png");
    background-position: center;
    background-size: 24rem;
}

.middle-background{
    text-align: center;
    width: 100%;
    background-image: url("../../public/images/home/background2.png");
    background-size: cover;
    background-position:center;
    color: white;
}

.bottom-background{
    background-image: url("../../public/images/home/background3.png");
    background-size: cover;
    background-position:center;
}